import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const AttributesHome = () => import('./Index.vue');

const upgradeParams = {
  featureFlagKey: 'CUSTOM_ATTRIBUTES',
  upgradeTitle: 'ATTRIBUTES_MGMT.UPGRADE_PAGE.TITLE',
  upgradeDescription: 'ATTRIBUTES_MGMT.UPGRADE_PAGE.DESCRIPTION',
  upgradeButton: 'ATTRIBUTES_MGMT.UPGRADE_PAGE.BUTTON.UPGRADE',
  upgradeFeatures: [
    {
      key: 1,
      title: 'ATTRIBUTES_MGMT.UPGRADE_PAGE.FEATURES.DEFINE_ATTRIBUTES.TITLE',
      icon: 'add',
      description: 'ATTRIBUTES_MGMT.UPGRADE_PAGE.FEATURES.DEFINE_ATTRIBUTES.DESCRIPTION'
    },
    {
      key: 2,
      title: 'ATTRIBUTES_MGMT.UPGRADE_PAGE.FEATURES.MANAGE_ATTRIBUTES.TITLE',
      icon: 'settings',
      description: 'ATTRIBUTES_MGMT.UPGRADE_PAGE.FEATURES.MANAGE_ATTRIBUTES.DESCRIPTION'
    },
    {
      key: 3,
      title: 'ATTRIBUTES_MGMT.UPGRADE_PAGE.FEATURES.ASSIGN_ATTRIBUTES.TITLE',
      icon: 'tag',
      description: 'ATTRIBUTES_MGMT.UPGRADE_PAGE.FEATURES.ASSIGN_ATTRIBUTES.DESCRIPTION'
    },
    {
      key: 4,
      title: 'ATTRIBUTES_MGMT.UPGRADE_PAGE.FEATURES.SEARCH_ATTRIBUTES.TITLE',
      icon: 'search',
      description: 'ATTRIBUTES_MGMT.UPGRADE_PAGE.FEATURES.SEARCH_ATTRIBUTES.DESCRIPTION'
    },
  ]
};

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/custom-attributes'),
      component: SettingsContent,
      props: {
        headerTitle: 'ATTRIBUTES_MGMT.HEADER',
        icon: 'code',
        showNewButton: false,
        ...upgradeParams
      },
      children: [
        {
          path: '',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'attributes_list',
          component: AttributesHome,
          meta: {
            permissions: ['administrator'],
          },
        },
      ],
    },
  ],
};
