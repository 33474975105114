import { frontendURL } from '../../../../helper/URLHelper';

const SettingsWrapper = () => import('../SettingsWrapper.vue');
const Index = () => import('./Index.vue');

const upgradeParams = {
  featureFlagKey: 'SLA',
  upgradeTitle: 'SLA.UPGRADE_PAGE.TITLE',
  upgradeDescription: 'SLA.UPGRADE_PAGE.DESCRIPTION',
  upgradeButton: 'SLA.UPGRADE_PAGE.BUTTON.UPGRADE',
  upgradeFeatures: [
    {
      key: 1,
      title: 'SLA.UPGRADE_PAGE.FEATURES.POLICIES.TITLE',
      icon: 'shield-checkmark',
      description: 'SLA.UPGRADE_PAGE.FEATURES.POLICIES.DESCRIPTION'
    },
    {
      key: 2,
      title: 'SLA.UPGRADE_PAGE.FEATURES.TRACKING.TITLE',
      icon: 'timer',
      description: 'SLA.UPGRADE_PAGE.FEATURES.TRACKING.DESCRIPTION'
    },
    {
      key: 3,
      title: 'SLA.UPGRADE_PAGE.FEATURES.NOTIFICATIONS.TITLE',
      icon: 'alert',
      description: 'SLA.UPGRADE_PAGE.FEATURES.NOTIFICATIONS.DESCRIPTION'
    },
    {
      key: 4,
      title: 'SLA.UPGRADE_PAGE.FEATURES.REPORTS.TITLE',
      icon: 'clipboard-pulse',
      description: 'SLA.UPGRADE_PAGE.FEATURES.REPORTS.DESCRIPTION'
    },
  ]
};

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/sla'),
      component: SettingsWrapper,
      props: {
        ...upgradeParams
      },
      children: [
        {
          path: '',
          name: 'sla_wrapper',
          meta: {
            permissions: ['administrator'],
          },
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'sla_list',
          meta: {
            permissions: ['administrator'],
          },
          component: Index,
        },
      ],
    },
  ],
};
