/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../helper/URLHelper';
const ContactsView = () => import('./components/ContactsView.vue');
const ContactManageView = () => import('./pages/ContactManageView.vue');

const upgradeParams = {
  featureFlagKey: 'CRM',
  upgradeTitle: 'CONTACTS_PAGE.UPGRADE_PAGE.TITLE',
  upgradeDescription: 'CONTACTS_PAGE.UPGRADE_PAGE.DESCRIPTION',
  upgradeButton: 'CONTACTS_PAGE.UPGRADE_PAGE.BUTTON.UPGRADE',
  upgradeFeatures: [
    {
      key: 1,
      title: 'CONTACTS_PAGE.UPGRADE_PAGE.FEATURES.MANAGE_CONTACTS.TITLE',
      icon: 'people',
      description: 'CONTACTS_PAGE.UPGRADE_PAGE.FEATURES.MANAGE_CONTACTS.DESCRIPTION'
    },
    {
      key: 2,
      title: 'CONTACTS_PAGE.UPGRADE_PAGE.FEATURES.CONTACT_SEGMENTS.TITLE',
      icon: 'filter',
      description: 'CONTACTS_PAGE.UPGRADE_PAGE.FEATURES.CONTACT_SEGMENTS.DESCRIPTION'
    },
    {
      key: 3,
      title: 'CONTACTS_PAGE.UPGRADE_PAGE.FEATURES.CUSTOM_FIELDS.TITLE',
      icon: 'data-bar-horizontal',
      description: 'CONTACTS_PAGE.UPGRADE_PAGE.FEATURES.CUSTOM_FIELDS.DESCRIPTION'
    },
    {
      key: 4,
      title: 'CONTACTS_PAGE.UPGRADE_PAGE.FEATURES.SEARCH_FILTER.TITLE',
      icon: 'search',
      description: 'CONTACTS_PAGE.UPGRADE_PAGE.FEATURES.SEARCH_FILTER.DESCRIPTION'
    },
  ]
}

export const routes = [
  {
    path: frontendURL('accounts/:accountId/contacts'),
    name: 'contacts_dashboard',
    meta: {
      permissions: ['administrator', 'agent'],
    },
    component: ContactsView,
    props: {
      ...upgradeParams
    }
  },
  {
    path: frontendURL('accounts/:accountId/contacts/custom_view/:id'),
    name: 'contacts_segments_dashboard',
    meta: {
      permissions: ['administrator', 'agent'],
    },
    component: ContactsView,
    props: route => {
      return {
        segmentsId: route.params.id,
        ...upgradeParams
      };
    },
  },
  {
    path: frontendURL('accounts/:accountId/labels/:label/contacts'),
    name: 'contacts_labels_dashboard',
    meta: {
      permissions: ['administrator', 'agent'],
    },
    component: ContactsView,
    props: route => {
      return {
        label: route.params.label,
        ...upgradeParams
      };
    },
  },
  {
    path: frontendURL('accounts/:accountId/contacts/:contactId'),
    name: 'contact_profile_dashboard',
    meta: {
      permissions: ['administrator', 'agent'],
    },
    component: ContactManageView,
    props: route => {
      return {
        contactId: route.params.contactId,
        ...upgradeParams
      };
    },
  },
];
